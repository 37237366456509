import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The Smallstep Platform is an opinionated, extensible toolkit for DevSecOps public key infrastructure (PKI).
It contains a number of components that can be combined to deliver automated certificate management for a broad set of use cases and implementations.
The components include:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https:/prof.infra.smallstep.com/docs/platform/authorities"
          }}>{`Authorities`}</a></strong>{`— Certificate or registration authorities powered by `}<inlineCode parentName="li">{`step-ca`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https:/prof.infra.smallstep.com/docs/platform/provisioners"
          }}>{`Provisioners`}</a></strong>{`— Machinery for securely automating certificate issuance`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https:/prof.infra.smallstep.com/docs/platform/inventories"
          }}>{`Inventories`}</a></strong>{`— Catalogs of entities like hosts, services, and people for use with templates and provisioners`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https:/prof.infra.smallstep.com/docs/platform/templates"
          }}>{`Templates`}</a></strong>{`— Customization of x.509 or SSH certificates for any use case`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https:/prof.infra.smallstep.com/docs/platform/events"
          }}>{`Events`}</a></strong>{`— Observability and alerting for certificate lifecycle activities`}</li>
    </ul>
    <h2>{`Learn More`}</h2>
    <p>{`Read more about the Smallstep Platform using the links above or `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/request-demo/"
      }}>{`schedule time`}</a>{` with Smallstep to learn how the platform can help your project.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      